import * as Sentry from '@sentry/nextjs'
import axios from 'axios'
import nookies from 'nookies'
import React, { createContext, FC, useContext, useEffect, useState } from 'react'
import useSWR from 'swr'

import { ReadProductResponse } from '@/api-utils/service-requests/product.types'
import { useClientSideApiHandler } from '@/api-utils/service-requests/utils'
import { Item } from '@/models/types'
import { TRANSLATION_COOKIE } from '@/utils/constants'
import { getHeadersWithSentryTraceId } from '@/utils/metrics'

type SelectedItemContext = {
  cartItemKey: string | null
  closeItemDrawer: () => void
  handleSelectCartItem: (itemId: string, itemCartKey: string) => void
  handleSelectItem: (item: Item) => void
  isItemCommentAllowed: boolean
  isItemDrawerOpen: boolean
  isLoadingItem: boolean
  selectedItem: Item | null
}

const selectedItemContext: React.Context<SelectedItemContext> = createContext({
  cartItemKey: undefined as unknown as SelectedItemContext['cartItemKey'],
  closeItemDrawer: undefined as unknown as SelectedItemContext['closeItemDrawer'],
  handleSelectCartItem: undefined as unknown as SelectedItemContext['handleSelectCartItem'],
  handleSelectItem: undefined as unknown as SelectedItemContext['handleSelectItem'],
  isItemCommentAllowed: undefined as unknown as SelectedItemContext['isItemCommentAllowed'],
  isItemDrawerOpen: undefined as unknown as SelectedItemContext['isItemDrawerOpen'],
  isLoadingItem: undefined as unknown as SelectedItemContext['isLoadingItem'],
  selectedItem: undefined as unknown as SelectedItemContext['selectedItem'],
})

/*
  This Context provider is used for keeping track of the selected Item to display in the ItemDrawer.
  Since tha all the Item data is not available from the Inventory, we need to fetch the full data from the product endpoint (/api/product/:id).
  In the moment of this writing, the missing data in Inventory is "variantGroups" and a different source for the image.

  We need two states to control the sliding animation of the Item drawer so the content-component can wait with unmounting until it has animated out of screen.

*/
function useSelectedItemContextProvider(isItemCommentAllowed: boolean, inventory: Item[]) {
  const [selectedItem, setSelectedItem] = useState<Item | null>(null)
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)
  const [cartItemKey, setCartItemKey] = useState<string | null>(null)
  const lang = nookies.get(null)[TRANSLATION_COOKIE]

  const readProductHandler = useClientSideApiHandler(ReadProductResponse)

  const closeItemDrawer = () => {
    setIsDrawerOpen(false)
  }

  const handleSelectCartItem = (itemId: string, itemCartKey: string) => {
    const item = inventory.find((item) => item.sku === itemId)
    if (!item) return
    setCartItemKey(itemCartKey)
    setSelectedItem(item)
    setIsDrawerOpen(true)
  }

  const handleSelectItem = React.useCallback((item: Item) => {
    setSelectedItem(item)
    setIsDrawerOpen(true)
  }, [])

  useEffect(() => {
    const timer = setTimeout(() => {
      if (!isDrawerOpen) {
        setSelectedItem(null)
        setCartItemKey(null)
      }
    }, 300)

    return () => clearTimeout(timer)
  }, [isDrawerOpen])

  const id = selectedItem?.sku ?? ''

  const { data, isValidating } = useSWR(
    id ? (lang ? `/api/product/${id}?lang=${lang}` : `/api/product/${id}`) : null,
    async (url: string) => {
      try {
        const response = await axios.get(url, {
          validateStatus: () => true,
          headers: getHeadersWithSentryTraceId({}),
        })
        return await readProductHandler(response)
      } catch (error) {
        Sentry.captureException(error, { extra: { source: 'SelectedItemContext.fetchProduct' } })
      }
    },
    {
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: true,
    }
  )

  return {
    handleSelectCartItem,
    closeItemDrawer,
    selectedItem: data ?? selectedItem,
    isLoadingItem: isValidating,
    isItemCommentAllowed,
    isItemDrawerOpen: isDrawerOpen,
    cartItemKey,
    handleSelectItem,
  }
}

export const SelectedItemContextProvider: FC<{
  isItemCommentAllowed: boolean
  inventory: Item[]
}> = ({ isItemCommentAllowed, inventory, children }) => {
  const context = useSelectedItemContextProvider(isItemCommentAllowed, inventory)

  return <selectedItemContext.Provider value={context}>{children}</selectedItemContext.Provider>
}

export const useSelectedItem = (): SelectedItemContext => useContext(selectedItemContext)
